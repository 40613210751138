.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;

  .karizma-logo {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 9.375rem !important;
    
    &:hover {
      cursor: pointer;
    }

    span {
      color: $abbey;
      font-size: 0.625rem;
      font-family: "TTNormsRegular";
    }
  }

  .footer-terms {
    display: flex;
    color: $abbey;
    font-size: 0.875rem;
    font-family: "TTNormsBold";
    margin-left: 2.25rem;

    p {
      &:last-child {
        margin-left: 2rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .social {
    display: flex;
    flex-direction: column;
    align-items: center;

    .social-icons {
      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }

    span {
      color: $abbey;
      font-size: 1rem;
      font-family: "TTNormsRegular";
    }
  }

  .right-footer {
    display: flex;
    align-items: center;

    .languages {
      span {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .change-region {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      span {
        color: $abbey;
        font-size: 0.875rem;
        margin-right: 0.25rem;
        margin-left: 0.5rem;
        font-family: "TTNormsBold";
      }

      &:hover {
        cursor: pointer;
      }  
    }
  }
}

// arabic version
.arabic {
  .footer {
    direction: rtl;

    .footer-terms {
      p {
        &:last-child {
          margin-right: 2rem;
          margin-left: 0;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    .social-icons {
      display: flex;
      flex-direction: row-reverse;
    }

    .right-footer {
      .change-region {
        flex-direction: row-reverse;
        margin-right: 2.5rem;
      }
    }
  }

  .karizma-logo span {
    direction: ltr;
  }
}

@media (max-width: $mobileWidth) {
  .footer {
    flex-direction: column;
    padding: 1.5rem 1rem;

    .karizma-logo {
      margin-bottom: 1rem;
    }

    .footer-terms {
      margin-left: 0;
      margin-bottom: 2.5rem;
      margin-top: 1rem;
    }

    .right-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      .change-region {
        span {
          font-size: 0.875rem;
        }
      }
    }
  }
}
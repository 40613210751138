body  {
  margin: 0;
  background: $white;
  font-variant-ligatures: none;
}

p {
  margin: 0;
}

button {
  border: none;
  background: transparent;
}

a {
  color: $abbey;
  text-decoration: none;
}

.button {
  border-radius: 0.938rem;
  border: 1px solid $mediumGray;
  padding: 0.75rem 1.875rem;
  color: $black;
  font-size: 1rem;
  font-family: "TTNormsBold";

  &:hover {
    cursor: pointer;
  }
}

.button.gradient:hover {
  background-position: right;
}

.button.gradient {
  text-decoration: none;
  color: $white;   
  background-image: linear-gradient(78deg, $electricViolet 0%, $aquamarine 100%); 
  background-size: 130% ;
  background-position: left;
  transition: 300ms background-position ease-in-out;
}


.qr-code {
  display: flex;
  width: fit-content;
  border: 1px solid $mediumGray;
  border-radius: 0.75rem;
  padding: 0.25rem;

  @media (max-width: $mobileWidth) {
    display: none;
  }
}

// used in footer and header
.languages {
  margin-right: 2.5rem;
  color: $abbey;

  span {
    font-size: 0.875rem;
    font-family: "TTNormsBold";

    &:last-child {
      margin-left: 1.25rem;
    }
  }

  .active {
    color: $black;
  }
}


.gray-text, .ghost-text {
  font-size: 1.125rem;
  font-family: "TTNormsRegular";

  @media (max-width: $mobileWidth) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.gray-text {
  color: $regentGray;
  line-height: 1.75rem;
}

.ghost-text {
  color: $ghost;
}

.header-text {
  font-size: 2.5rem;
  font-family: "TTNormsBold";
  margin-bottom: 1rem;

  @media (max-width: $mobileWidth) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.toggle-wrapper {
  width: fit-content;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 0.75rem;
  background: $athensGray;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    color: $black;
    z-index: 2;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-family: "TTNormsBold";
    transition: all .3s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  button.active {
    border-radius: 0.75rem;
    color: $white;
  }

  .animated-block {
    z-index: 1;
    pointer-events: none;
    background: $purple;
    height: 2.25rem;
    left: 0.5rem;
    position: absolute;
    border-radius: 0.75rem;
    transition: all .3s ease-in;
  }

  .animated-block.userTypes {
    width: 48%;
  }
  // Candidates/Employers Arabic Language
  .animated-block.userTypes.ar {
    width: 36%;
  }

  .animated-block.languages {
    width: 35%;
  }

  .animated-block.active.userTypes {
    width: 51%;
    left: calc(100% - 53%);
  }

  // Candidates/Employers Arabic Language
  .animated-block.active.userTypes.ar {
    width: 56%;
    left: calc(100% - 59%);
  }

  .animated-block.active.languages {
    width: 60%;
    left: calc(100% - 65%);
  }
}

.header, .footer {
  background: $athensGray;
  color: $black;
  border-radius: 1.5rem;
  margin: 0 1.75rem 0 1.75rem;

  @media (max-width: $mobileWidth) {
    margin: 0 1rem;
  }
}

.container {
  padding: 1.75rem 0 1.75rem 0;
  color: $black;

  @media (max-width: $mobileWidth) {
    padding: 1rem 0 1.75rem 0;
  }
}

.hidden {
  display: none;
}

.store-images {

  @media (max-width: $mobileWidth) {
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 11.25rem;
  
    @media (max-width: $mobileWidth) {
      max-width: 9.375rem;
    }

    &:last-child {
      margin-left: 1.25rem;

      @media (max-width: $mobileWidth) {
        margin-left: 0.75rem;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.purple-text {
  color: $purple !important;
}

.malibu-text {
  color: $malibu !important;
}

.image-block {
  img {
    width: 100%;
    height: 100%;
  }
}
$white: white;
$black: #000000;
$abbey: #54585F;
$aquamarine: #42FFE8;
$ebony: #161635;
$eastBay: #4E4E83;
$eastBay2: #404070;
$malibu: #4AC8FB;
$ghost: #C7CED9;
$modalBackground: rgba(0, 0, 0, 0.40);

$purple: #7D4AFB;
$purple70: rgba(125, 74, 251, 0.70);
$electricViolet: #8826FF;

$mediumGray: #E2E4E7;
$athensGray: #F5F6F8;
$regentGray: #8A93A0;

$oceanGreen: #36B37E;
$milanoRed: #BF2600;
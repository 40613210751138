// USA version
.container.candidates {
  background: $ebony;
  color: $white;

  .qr-code {
    border: none;
    
    img {
      border-radius: 0.5rem;
    }
  }

  .header {
    @media (max-width: $mobileWidth) {
      .logo-image {
        margin-bottom: 0;
      }
      .sign-buttons {
        display: none;
      }
    }
  }

  .header, .footer {
    background: $eastBay;
    
    span, p {
      color: $white;
    }
  }

  .whisper-text {
    color: $malibu;
    font-size: 5rem;
    font-family: "Whisper";
  }

  .hire-block, .ready-to-exp-block {
    .whisper-text {
      color: $malibu;
    }
  }

  .join-thousands-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7.5rem;
    
    @media (max-width: $mobileWidth) {
      margin-bottom: 5rem;
    }

    p {
      text-align: center;
      font-size: 3rem;
      font-family: "TTNormsMedium";
      line-height: 3.75rem;
      margin-bottom: 2.5rem;

      @media (max-width: $mobileWidth) {
        font-size: 1.5rem;
        line-height: 2rem;

        .whisper-text {
          font-size: 2.25rem;
          line-height: 2rem;
        }
      }
    }

    .dream-members {
      display: flex;
      flex-direction: row;
      margin: 0 1.75rem 0 1.75rem;
      
      @media (max-width: $mobileWidth) {
        margin: 0 1rem;
      }

      div {
        padding-left: 1.25rem;
        
        @media (max-width: $mobileWidth) {
          padding-left: 0.75rem;
        }

        &:first-child {
          padding-left: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        
        &:nth-child(2n) {
          padding-bottom: 2.5rem;
        }
      }
    }
  }

  .looking-job-block {
    background-color: $eastBay;
    border-radius: 1.5rem;
    margin: 0 1.75rem 0 1.75rem;
    padding: 6.25rem;

    @media (max-width: $mobileWidth) {
      padding: 1rem;
      margin: 0 1rem;
    }

    p {
      text-align: center;
      color: $white;
      font-size: 2rem;
      font-family: "TTNormsMedium";
      line-height: 2.75rem;
      word-wrap: break-word;

      @media (max-width: $mobileWidth) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  .reviews-block {
    .review {
      border: 1px solid $eastBay2;
    }
    .review span, .reviewer-details p {
      color: $white;
    }
    .reviews-list .reviewer-details span {
      color: $athensGray;
    }
  }

  .carousel-controls {
    .carousel-button {
      border-color: $eastBay;
    }

    .carousel-points {
      button .carousel-point  {
        background: $purple;
      }
      button .carousel-point.selected {
        background: $white;
      }
    }
  }

  .ready-to-exp-block {
    @media (max-width: $mobileWidth) {
      span {
        font-size: 3.75rem;
      }
    }
  }
  
  .header {

    .button.gradient:hover {
      background-position: right !important;
    }
    
    .button.gradient {
      border: none;
      text-decoration: none;
      color: $white;   
      background-image: linear-gradient(78deg, $electricViolet 0%, $aquamarine 100%) !important; 
      background-size: 130% !important;
      background-position: left !important;
      transition: 300ms background-position ease-in-out;
    }

    .languages {
      .inactive {
        color: $regentGray;
      }
    }
  }

  .footer {
    .right-footer {
      span {
        color: $white;
      }
      span.inactive {
        color: $regentGray;
      }
    }
  }

  .container {
    .terms-of-service {
      span, h2, p {
        color: $white;
      }
    }
  }
}
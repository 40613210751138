// for candidates
.arabic {
  .join-thousands-block {
    @media (max-width: $mobileWidth) {
      margin-top: 5rem;

      p {
        font-weight: bold;
      }
    }

    margin-top: 7.5rem;
  }

  .qr-code {
    border: none;
    
    img {
      border-radius: 0.75rem;
    }
  }

  .hire-block {
    .store-images {
      margin-bottom: 2rem;
    }

    p {
      font-weight: bold;
    }

    &.active {
      animation-name: slideIn;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
    }

    &.inactive {
      display: none;
      transform: scale(0);
    }
  }

  .ready-to-exp-block {
    p {
      font-weight: bold;
      font-size: 3rem;
    }
    
    .malibu-text {
      font-family: "TTNormsBold";
      font-size: 3rem;
    }
    
    .right-block {
      img {
        &.active {
          animation-name: slideIn;
          animation-duration: 0.3s;
          animation-timing-function: ease-in-out;
        }

        &.inactive {
          display: none;
          transform: scale(0);
        }
      }
    }
  }
}

.arabic {
  // same for header and footer
  .languages {
    direction: ltr;
    display: flex;
    flex-direction: row-reverse;
    
    span {
      &:last-child {
        margin-right: 1.25rem;
        margin-left: 0;
      }
    }
  }

  .header-text {
    font-weight: bold;
  }

  .store-images {
    img {
      margin-left: 1.25rem;

      @media (max-width: $mobileWidth) {
        margin-left: 0.875rem !important;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }

  .hire-block {
    direction: rtl;

    .left-block {
      width: 50%;
      margin-right: 5rem;

      @media (max-width: $mobileWidth) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2.5rem;

        .store-images {
          margin: 0;
        }
      }
    }
  }

  .short-videos-block {
    .purple-text {
      font-size: 3rem;

      @media (max-width: $mobileWidth) {
        font-size: 2.25rem;
      }
    }
  }
  
  .universal-block {
    direction: rtl;
  }

  .showcase-block {
    direction: rtl;

    .left-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
    
      @media (max-width: $mobileWidth) {
        width: 100%;
      }
    }
  }

  .serving-others-block {
    direction: rtl;
    padding-right: 2rem;
    
    @media (max-width: $mobileWidth) {
      padding-right: 1rem;
      p {
        margin-bottom: 1rem !important;
      }
    }
  }

  .reviews-block {
    @media (max-width: $mobileWidth) {
      p {
        font-weight: bold;
      }
    }

    .reviews-list {      
      .review {
        margin-right: 0;
        margin-left: 1.25rem;
        
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .ready-to-exp-block {
    direction: rtl;
    display: flex;
    justify-content: flex-end;

    .left-block {
      width: 40%;
      margin-left: 0;
    
      @media (max-width: $mobileWidth) {
        width: 100%;
        margin-bottom: 2rem;
      }

      .purple-text {
        font-size: 3.25rem;

        @media (max-width: $mobileWidth) {
          font-size: 2.875rem;
        }
      }
    }

    .right-block {
      img {
        &.active {
          animation-name: slideIn;
          animation-duration: 0.3s;
          animation-timing-function: ease-in-out;
        }

        &.inactive {
          display: none;
          transform: scale(0);
        }
      }
    }
  }
}
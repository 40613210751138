@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes unfoldIn {
  0% {
    transform:scaleY(0) scaleX(0);
    border-radius: 10%;
  }
  100% {
    transform:scaleY(1) scaleX(1);
    border-radius: 0%;
  }
}

@keyframes zoomIn {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}
.modal-container {
  transform: scale(0);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $modalBackground;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .close-icon {
    display: flex;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &.active {
    transform: scaleY(.01) scaleX(0);
    animation: unfoldIn .2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    
    .modal-background {
      .modal-content {
        transform:scale(0);
        animation: zoomIn .2s .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }
}

.modal-content {
  background-color: $white;
  border-radius: 0.938rem;
  position: relative;
  text-align: center;
  padding: 0.75rem 0.75rem 1.5rem 0.75rem;
  width: 25%;

  @media (max-width: $mobileWidth) {
    width: 80%;
  }

  .modal-header {
    color: $black;
    font-size: 1.25rem;
    font-family: "EudoxusSansBold";
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0.75rem;
  }
}

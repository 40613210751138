.hire-block {
  transition: all .3s ease-in;
  display: flex;;
  justify-content: space-between;
  
  @media (max-width: $mobileWidth) {
    flex-direction: column;
  }

  .left-block {
    margin-left: 3.125rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $mobileWidth) {
      margin-left: 0;
      margin: 0 auto;
    }

    p {
      font-size: 4.5rem;
      font-family: "TTNormsBold";
      line-height: 5rem;

      @media (max-width: $mobileWidth) {
        text-align: center;
        font-size: 2.875rem;
        line-height: 3.25rem;
        margin: 1.5rem 1rem;
      }

      .sub-title {
        color: $abbey;
        line-height: 2rem;
        font-size: 1.5rem;
        margin-top: 1.25rem;
        font-family: "TTNormsRegular";
      }
    }
    
    .whisper-text {
      font-size: 6.875rem;
      color: $purple;
      font-family: "Whisper";

      @media (max-width: $mobileWidth) {
        font-size: 3.75rem;
      }
    }

    .store-images {
      margin-top: 3rem;
      margin-bottom: 3rem;

      @media (max-width: $mobileWidth) {
        margin-top: 0;
        display: flex;
        margin: 0 2rem 2rem 2rem;
      }
    }
  }

  .right-block {
    width: 50%;
    @media (max-width: $mobileWidth) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    
      &.active {
        animation-name: slideIn;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
      }

      &.inactive {
        display: none;
        transform: scale(0);
      }
    }
  }
}


.trusted-by-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7.5rem;
  margin-top: 5rem;
  
  @media (max-width: $mobileWidth) {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  p {
    color: $black;
    font-size: 2.5rem;
    font-family: "TTNormsBold";
    margin-bottom: 2.5rem;

    @media (max-width: $mobileWidth) {
      font-size: 2.25rem;
    }
  }

  .company-logos {
    text-align: center;
  
    img {
      margin-right: 5rem;
    }
  }

  .mobile-company-logos {
    text-align: center;
    
    > div {
      margin-bottom: 0.5rem;
    }
  }
}

.short-videos-block {
  background: $athensGray;
  border-radius: 1.5rem;
  padding: 6.25rem 6.25rem 0 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem 1.5rem 0 1.5rem;

  @media (max-width: $mobileWidth) {
    padding: 0;
    margin: 0 1rem 0 1rem;
  }

  picture {
    img {
      // need to handle image left shadow
      margin-left: -5rem;
      width: 100%;
      height: 100%;

      @media (max-width: $mobileWidth) {
        
        height: 100%;
        margin-left: 0;
        border-radius: 0 0 1.5rem 1.5rem;
      }
    }
  }

  p {
    text-align: center;
    color: $black;
    font-size: 3rem;
    font-family: "TTNormsMedium";

    @media (max-width: $mobileWidth) {
      margin: 1rem;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    span {
      color: $purple;
      font-size: 5rem;
      font-family: "Whisper";
    
      @media (max-width: $mobileWidth) {
        font-size: 2.25rem;
      }
    }
  }
}

.universal-block, .showcase-block, .autointerview-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7.5rem;

  @media (max-width: $mobileWidth) {
    flex-direction: column-reverse;
    margin-top: 4rem;
    &.not-reverse {
      flex-direction: column;
    }
  }

  > div {
    width: 50%;
    @media (max-width: $mobileWidth) {
      width: 100%;
    }
  }
  
  .text-block {
    @media (max-width: $mobileWidth) {
      width: 90%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  
    > div {
      margin: 5rem;
      @media (max-width: $mobileWidth) {
        margin: 0;
      }
    }

    .store-images, .chrome-image {
      margin-top: 3rem;
    }

    .chrome-image {
      @media (max-width: $mobileWidth) {
        margin-bottom: 3rem;
      }
  
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.autointerview-block {
  margin-bottom: 2rem !important;
  @media (max-width: $mobileWidth) {
    display: none !important;
  }
}

.showcase-block, .autointerview-block {
  display: flex;
  flex-direction: row;
  background: $athensGray;
  border-radius: 1.5rem;
  margin: 0rem 1.5rem 0 1.5rem;

  @media (max-width: $mobileWidth) {
    flex-direction: column;
    margin: 0rem 1rem 0 1rem;
    padding: 1rem 1rem 0 1rem;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $mobileWidth) {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
    }

    .store-images {
      margin-top: 1.5rem;

      @media (max-width: $mobileWidth) {
        display: flex;
        justify-content: center;
        
        img {
          max-width: 9rem;
        }
      }
    }
  }
}

.serving-others-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  padding-right: 7.313rem;
  margin: 1.5rem 1.5rem 0 1.5rem;
  background: $athensGray;
  border-radius: 1.5rem;

  > div {
    width: 50%;

    @media (max-width: $mobileWidth) {
      width: 100%;
    }
  }

  @media (max-width: $mobileWidth) {
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .right-block {
    width: 50%;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobileWidth) {
      width: 100%;
    }

    p {
      width: 80%;
      margin: 0 auto;

      @media (max-width: $mobileWidth) {
        margin-top: 1rem;
        margin-bottom: 0.75rem;
        width: 100%;
      }
    }

    .gray-text {
      margin: 0 auto;
      width: 80%;

      @media (max-width: $mobileWidth) {
        width: 100%;
      }
    }
  }

  .gray-text {
    line-height: 2rem;
    font-family: 'EudoxusSans';

    @media (max-width: $mobileWidth) {
      line-height: 1.5rem;
    }
  }
}

.reviews-block {
  margin: 7.5rem 0;
  
  @media (max-width: $mobileWidth) {
    margin: 5rem 0;
  }

  p {
    text-align: center;
    font-size: 2.5rem;
    font-family: "TTNormsBold";

    @media (max-width: $mobileWidth) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .reviews-list-mobile {
    .review {
      border: 1px solid $mediumGray;
      border-radius: 1.5rem;
      min-height: 18rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $mobileWidth) {
        margin-top: 1.5rem !important;
        margin: 0 0.5rem;
        padding: 1.5rem;
      }
      
      .stars {
        margin-bottom: 1.5rem;
      }

      span {
        color: $abbey;
        font-size: 1.375rem;
        font-family: "TTNormsRegular";
        line-height: 1.75rem;

        @media (max-width: $mobileWidth) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }

      .reviewer-details {
        display: flex;

        img {
          margin-right: 0.75rem;
        }

        p {
          font-size: 1.125rem;
          font-family: "TTNormsBold";

          @media (max-width: $mobileWidth) {
            font-size: 1.125rem;
            line-height: 1.75rem;
          }
        }

        span {
          color: $abbey;
          font-size: 0.875rem;
          font-family: "TTNormsRegular";
        }
      }
    }
  }

  .reviews-list {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: auto;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;  
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $mobileWidth) {  
      overflow-x: scroll;
      justify-content: flex-start;
      -ms-overflow-style: none;  
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .review {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 28%;
      margin-top: 2.5rem;
      border: 1px solid $mediumGray;
      border-radius: 1.5rem;
      padding: 3rem 2.25rem 3rem 2.25rem;
      margin-left: 1.25rem;

      @media (max-width: $mobileWidth) {
        min-width: 85%;
        margin-top: 1rem;
        padding: 1.5rem;
        justify-content: space-between;
      
        &:first-child {
          margin-left: 1.75rem !important;
        }
        &:last-child {
          margin-right: 0.75rem !important;
        }
      }
      
      &:first-child {
        margin-left: 3rem;
      }

      .stars {
        margin-bottom: 1.5rem;
      }

      span {
        color: $abbey;
        font-size: 1.375rem;
        font-family: "TTNormsRegular";
        line-height: 1.75rem;

        @media (max-width: $mobileWidth) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }

      .reviewer-details {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 0.75rem;
        }

        p {
          font-size: 1.125rem;
          font-family: "TTNormsBold";

          @media (max-width: $mobileWidth) {
            font-size: 1.125rem;
            line-height: 1.75rem;
          }
        }

        span {
          color: $abbey;
          font-size: 0.875rem;
          font-family: "TTNormsRegular";
        }
      }
    }
  }

  .carousel-button {
    display: flex;
    padding: 0.875rem;
    border: 1px solid $mediumGray;
    border-radius: 0.938rem;
    
    &:hover {
      cursor: pointer;
    }
  }

  .carousel-button.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .carousel-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobileWidth) {
      margin: 1.5rem 1rem;
      justify-content: space-between;
    }

    .carousel-points {
      margin: 0 3rem;
      display: flex;
      flex-direction: row;
       
      @media (max-width: $mobileWidth) {
        margin: 0;
      }
      
      .carousel-point {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: rgba(125, 74, 251, 0.10);
        margin-left: 0.75rem;

        @media (max-width: "400px") {
          margin-left: 0.5rem;
          width: 9px;
          height: 9px;
          border-radius: 4.5px;
        }
      
        &:first-child {
          margin-left: 0;
        }
      }
      .carousel-point.selected {
        background: $purple;
      }  
    }
  }
}

.contact-us-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: $purple70;
  border-radius: 1.5rem;
  padding: 9.25rem 6.25rem;
  color: $white;
  margin: 0rem 1.5rem 7rem 1.5rem;

  @media (max-width: $mobileWidth) {
    padding: 1.5rem;
    margin: 0rem 1rem 5rem 1rem;
  }

  p {
    font-size: 2.75rem;
    font-family: "TTNormsBold";
    margin-bottom: 0.5rem;

    @media (max-width: $mobileWidth) {
      font-size: 1.5rem;
    }
  }

  span {
    color: $athensGray;
    font-size: 2rem;
    font-family: "TTNormsMedium";
    margin-bottom: 3.5rem;

    @media (max-width: $mobileWidth) {
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
    }
  }

  .send-email {
    display: flex;
    flex-direction: row;

    @media (max-width: $mobileWidth) {
      width: 100%;
      flex-direction: column;
      padding: 0 1.5rem 0 1.5rem;
    }

    button {
      &:hover {
        cursor: pointer;
      }
    }

    .error-message {
      position: absolute;
      margin-top: 3.5rem;
      font-size: 0.9rem;
      color: $milanoRed;
    }

    .success-message {
      position: absolute;
      margin-top: 3.5rem;
      font-size: 1.05rem;
      color: $oceanGreen;
    }
    
    .input-container {
      width: 100%;
      display: flex;

      input {
        border: none;
        background: $white;
        border-radius: 0.938rem;
        padding: 1.125rem 1.25rem ;
        font-size: 0.875rem;
        font-family: "TTNormsRegular";
        color: $black;

        @media (max-width: $mobileWidth) {
          max-width: none;
          text-align: center;
          margin-bottom: 0.75rem;
          width: 100%;
          font-size: 1rem;
        }

        @media (min-width: 1384px) {
          min-width: 17rem;
        }

        &:hover {
          outline: none;
        }

        &:focus {
          outline: 1px solid $electricViolet;
          border-radius: 0.938rem;

          @media (max-width: $mobileWidth) {
            outline: none;
          }
        }
      }
    }
    

    button {
      background: $black;
      border-radius: 0.938rem;
      padding: 1rem 2.5rem;
      margin-left: 0.75rem;
      color: $white;
      font-size: 1rem;
      font-family: "TTNormsBold";

      @media (max-width: $mobileWidth) {
        width: 100%;
        margin-left: 0;
      }
    }

    button.with-message {
      @media (max-width: $mobileWidth) {
        margin-top: 1rem;
      }
    }

    button.loading {
      padding: 0.5rem 2.5rem;
    }
  }
}

.ready-to-exp-block {
  display: flex;
  align-items: center;
  // 0.5rem take image
  margin-bottom: 7rem;

  @media (max-width: $mobileWidth) {
    flex-direction: column;
    margin-bottom: 5rem;
  }

  > div {
    width: 50%;

    @media (max-width: $mobileWidth) {
      width: 100%;
    }
  }

  .left-block {
    display: flex;
    flex-direction: column;
    margin-left: 6rem;

    @media (max-width: $mobileWidth) {
      margin-left: 0;

      .store-images {
        display: flex;
        justify-content: space-around;
        padding: 0 1.5rem;
      }
    }

    p {
      font-size: 3.25rem;
      font-family: "TTNormsBold";
      margin-bottom: 2.5rem;
      margin-top: 3.25rem;

      @media (max-width: $mobileWidth) {
        text-align: center;
        margin-top: 0;
        margin-bottom: 1.5rem;
        font-size: 2.875rem;
        line-height: 3.25rem;
      }

      span {
        color: $purple;
        font-size: 5rem;
        font-family: "Whisper";
      }
    }
  }

  .right-block {
    margin-top: 1rem;
    
    img {
      width: 100%;
      height: 100%;

      &.active {
        animation-name: slideIn;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
      }

      &.inactive {
        display: none;
        transform: scale(0);
      }
    }
  }
}
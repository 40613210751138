// 700
@font-face {
  font-family: 'TTNormsBold';
  src: url('../../public/fonts/TTNorms-Bold.woff2');
}

// 500
@font-face {
  font-family: 'TTNormsMedium';
  src: url('../../public/fonts/TTNorms-Medium.woff2');
}

// 400
@font-face {
  font-family: 'TTNormsRegular';
  src: url('../../public/fonts/TTNorms-Regular.woff2');
}

// 400
@font-face {
  font-family: 'Whisper';
  src: url('../../public/fonts/Whisper-Regular.woff2');
}

// 400
@font-face {
  font-family: 'EudoxusSans';
  src: url('../../public/fonts/EudoxusSans-Regular.woff2');
}

// 700
@font-face {
  font-family: 'EudoxusSansBold';
  src: url('../../public/fonts/EudoxusSans-Bold.woff2');
}


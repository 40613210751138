.header {
  padding: 1rem 1rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;


  .logo-image {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .right-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .languages {
      span {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .sign-buttons {
      
      button.white {
        margin-right: 0.75rem;
        transition: all 0.3s ease;
        
        &:hover {
          background: $mediumGray !important;
        }
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .header {
    flex-direction: column;
    padding: 1rem;

    .logo-image {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1.125rem;

      .languages {
        margin-right: 0;
      }
    }

    .right-navigation {
      width: 100%;
      
      .sign-buttons {
        width: 100%;

        button {
          width: 48%;
          height: 2.25rem;
          padding: 0.5rem 1.75rem;

          &:first-child {
            margin-right: 4%;
          }
        }
      }
    }
  }
}

.arabic {
  .header {
    direction: rtl;
  }

  .right-navigation {
    @media (max-width: $mobileWidth) {
      width: 100%;
      .languages {
        display: none;
      }
    }

    .sign-buttons {
      @media (max-width: $mobileWidth) {
        display: flex;
        width: 100%;        
      }

      button {
        @media (max-width: $mobileWidth) {
          font-size: 0.875rem;
          padding: 0;
        }

        &:first-child {
          margin-left: 0.75rem;
          margin-right: 2.5rem;

          @media (max-width: $mobileWidth) {
            margin-left: 0.75rem;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.container {
  .terms-of-service {
    padding: 1.75rem;
    padding-top: 0;
    margin-top: -1rem;
    width: 75%;
    margin-bottom: 5rem;

    @media (max-width: $mobileWidth) {
      width: fit-content;
    }

    h2 {
      color: $black;
      font-size: 2.5rem;
      font-family: "TTNormsBold";
      line-height: 3rem;
      margin-bottom: 3.75rem;
    }

    p {
      color: $abbey;
      font-size: 1.125rem;
      font-family: "TTNormsBold";
      line-height: 1.75rem;
      margin-top: 2rem;
    }

    span, p.sub-header {
      color: $abbey;
      font-size: 1.125rem;
      font-family: "TTNormsRegular";
    }

    p.sub-header {
      margin: 0.75rem 0;
    }

    .list {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      img {
        margin-right: 0.5rem;
      }
    }

    .underline {
      text-decoration: underline;
    }
  }
}